<template>
  <div>
    <div class="item_view" v-for="(item, index) in dataList" :key="`item_${index}`">
      <div class="title_tx">{{ item.title }}</div>
      <div class="content_tx">{{ item.content }}</div>
    </div>
    <el-empty v-if="dataList.length == 0" description="暂无数据" :image="require('@/assets/icon_empty.png')"></el-empty>
  </div>
</template>

<script>
import API from '@/common/axios';
export default {
  data() {
    return {
      dataList: []
    };
  },
  mounted() {
    API({
      url: "http://aiapi.biyao.com/llm/v1/model/user/habit",
      data: {
        externalUserId: this.$parent.selectUserInfo.externalUserid
      }
    }).then(res => {
      if (res.code == 200) {
        this.dataList = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  },

};
</script>

<style lang="scss" scoped>
.item_view {
  padding: 10px 20px;
  border-bottom: 1px solid var(--boder-color);

  &:first-child {
    padding-top: 30px;
  }
}

.title_tx {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.content_tx {
  color: #333;
  word-break: break-all;
  text-align: justify;
  font-size: 14px;
}
</style>